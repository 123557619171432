import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type QuickFormStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'quick-form' }
>;

export const getQuickFormStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);

  const content: QuickFormStaticContentSchema = {
    layout: slideLayout,
    type: 'quick-form',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset: slideTextLayout.textSizeOffset,
  };

  return content;
};
