import type { LanguageSettings } from '@core-api/editor/types/response';
import type { Series, VotingSeries } from '@mentimeter/http-clients';

export const seriesLanguageToLanguageSettings = (
  series: Series | VotingSeries,
): LanguageSettings => {
  return {
    presentationLanguage:
      (series.language as LanguageSettings['presentationLanguage']) ?? 'en',
  };
};
