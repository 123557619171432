import React, { forwardRef } from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui/box';
import { Box } from '@mentimeter/ragnar-ui/box';

interface PropsT {
  background?: BoxT['bg'];
  children?: React.ReactNode;
}

export const PreviewContainer = forwardRef<HTMLDivElement | undefined, PropsT>(
  // eslint-disable-next-line react/jsx-no-useless-fragment
  ({ background = 'transparent', children = <></> }: PropsT, ref) => {
    return (
      <Box
        ref={ref}
        bg={background}
        width="100%"
        overflow="hidden"
        alignItems="center"
        position="relative"
        extend={() => ({
          aspectRatio: '16/9',
        })}
      >
        {children}
      </Box>
    );
  },
);
