import type { MusicSettings as EditorMusicSettings } from '@core-api/editor/types/response';
import type { MusicSettings as PresentationMusicSettings } from '@core-api/presentation/types/response';
import type { Series } from '@mentimeter/http-clients';

export const musicSettingsToSeriesMusicUrl = (
  musicSettings: EditorMusicSettings | PresentationMusicSettings,
): Series['music_url'] => {
  if (musicSettings.playbackPolicy === 'quiz-enabled') {
    return musicSettings.url;
  }

  return '';
};
