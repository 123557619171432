import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';

type ImageStaticContentSchema = Extract<StaticContentSchema, { type: 'image' }>;

export const imagePositionToQuestionSlideImageType = (
  imagePosition: ImageStaticContentSchema['imagePosition'] | undefined,
): Question['slide_image_type'] => {
  return imagePosition === 'background' ? 'image-background' : 'image-frame';
};
