import type { Series } from '@mentimeter/http-clients';

export const DEFAULT_SLIDE_PREVIEW_BACKGROUND = 'bgStrong';

export function getThemeBackgroundColor(
  series: Series,
  currentQuestionId?: string,
): string {
  const currentQuestion =
    series.questions.find(
      (q) => q.id === currentQuestionId || q.admin_key === currentQuestionId,
    ) ?? series.questions[0];

  if (currentQuestion?.theme_settings?.background_color) {
    return currentQuestion.theme_settings.background_color;
  }

  if (series.theme.background_color) {
    return series.theme.background_color;
  }

  return DEFAULT_SLIDE_PREVIEW_BACKGROUND;
}
