import type { Series, VotingSeries } from '@mentimeter/http-clients';
import type { CompatibilitySlideDeck } from '../../compatibility-types';
import { getUnmigratedSlideDeckProperty } from '../../forward-compatibility/get-unmigrated-slidedeck-property';
import { InvalidProxySetError } from '../../utils/invalid-proxy-set-error';

type BaseCompatibilityLayerHandlerTarget =
  | Series
  | VotingSeries
  | CompatibilitySlideDeck;

export const baseSeriesCompatibilityLayerHandler = <
  TTarget extends BaseCompatibilityLayerHandlerTarget,
>({
  getMigratedSeriesProperty,
}: {
  getMigratedSeriesProperty: (
    target: TTarget,
    key: keyof Series | 'slides', // Slides is nested property which needs to be manually wrapped in a proxy
  ) => any;
}): ProxyHandler<TTarget> => ({
  get(
    target: TTarget,
    key:
      | keyof BaseCompatibilityLayerHandlerTarget
      | '__isProxy'
      | '__target'
      | 'isMigrated',
  ) {
    if (key === '__isProxy') {
      return true;
    }

    if ((key as any) === '__target') {
      return target;
    }

    if (key === 'isMigrated') {
      return Array.isArray((target as CompatibilitySlideDeck)?.slides);
    }

    if ((target as CompatibilitySlideDeck).slideDeckId) {
      return getMigratedSeriesProperty(
        target,
        key as Parameters<typeof getMigratedSeriesProperty>[1],
      );
    }

    return getUnmigratedSlideDeckProperty(
      target as Series,
      key as Parameters<typeof getUnmigratedSlideDeckProperty>[1],
    );
  },
  set(_: never, key: string) {
    throw new InvalidProxySetError(key, 'series');
  },
});
