import React, { useEffect, useRef, useState } from 'react';
import { PreviewContainer } from './PreviewContainer';

export function RevealWhenFirstVisible({
  children,
}: {
  children: React.ReactNode;
}) {
  const [visible, setVisible] = useState(false);
  const screencheck = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [container] = entries;

        if (container && container.isIntersecting) {
          setVisible(true);
        }
      },
      { rootMargin: '0px 0px 0px 0px' },
    );
    const container = screencheck.current;
    if (container) observer.observe(container);

    return () => {
      if (container) observer.unobserve(container);
    };
  }, []);

  return (
    <PreviewContainer ref={screencheck}>{visible && children}</PreviewContainer>
  );
}
