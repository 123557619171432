import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type BigStaticContentSchema = Extract<StaticContentSchema, { type: 'big' }>;

export const getBigStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);

  const content: BigStaticContentSchema = {
    layout: slideLayout,
    type: 'big',
    message: question.question,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    textAlignment: {
      textHorizontalAlign: slideTextLayout.textHorizontalAlign,
      textVerticalAlign: slideTextLayout.textVerticalAlign,
    },
    textSizeOffset: slideTextLayout.textSizeOffset,
  };

  return content;
};
