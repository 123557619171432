import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionSubTypeToSlideVisualization } from '../converters/question';
import { questionDimensionsToSlideScaleResponseRangeLabels } from '../converters/question/question-dimensions-to-slide-scale-response-range-labels';

type ScalesStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'scales' }
>;

export const getScalesStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );

  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: ScalesStaticContentSchema = {
    layout: slideLayout,
    type: 'scales',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset,
    interactiveContentStyling: {
      visualization: questionSubTypeToSlideVisualization(
        question.sub_type,
      ) as ScalesStaticContentSchema['interactiveContentStyling']['visualization'],
      showAverage: question.scales_total_average,
      responseRangeLabels: questionDimensionsToSlideScaleResponseRangeLabels(
        question.dimensions,
      ),
    },
  };

  return content;
};
