import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';
import type { ModuleId } from '@mentimeter/http-clients';

export class UnsupportedModuleIdError extends MentiError {
  constructor(
    {
      moduleId,
      property,
      value,
    }: {
      moduleId: ModuleId;
      property?: string | Array<string>;
      value?: unknown;
    },
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const errorMessage = `Unsupported module ID: ${String(moduleId)}`;
    const propertyMessage = `property: ${String(property)}`;

    let valueMessage = '';
    try {
      valueMessage = `value: ${typeof value === 'object' ? JSON.stringify(value) : String(value)}`;
    } catch (ex) {}

    const causeMessage = [propertyMessage, valueMessage]
      .filter(Boolean)
      .join(', ');

    super(errorMessage, {
      feature: 'compatibility-layer',
      ...options,
      cause: new Error(causeMessage),
    });

    this.name = 'UnsupportedModuleIdError';
  }
}
