import type { QuestionChoice } from '@mentimeter/http-clients';
import type { CompatibilityInteractiveContentChoice } from '../../../compatibility-types';

export const choiceToQuestionChoice = (
  choice: CompatibilityInteractiveContentChoice,
): QuestionChoice => {
  const image = choice.image;
  return {
    image_url: image?.presets?.source?.url,
    cropped_image_url: image?.presets?.original?.url,
    correct_answer: !!choice.markedCorrect,
    label: choice.title,
    image_alt_description: image?.altText,
  } as QuestionChoice;
};
