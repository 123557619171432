import type { ProfanitySettings } from '@core-api/editor/types/response';
import type { Series } from '@mentimeter/http-clients';

export const seriesProfanityLangToProfanitySettings = (
  series: Series,
): ProfanitySettings => {
  return {
    profanityFilters:
      series.profanity_lang === ''
        ? []
        : ((series.profanity_lang?.split(
            ',',
          ) as ProfanitySettings['profanityFilters']) ?? []),
  };
};
