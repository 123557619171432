import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionBulletSettingsToBulletsStyling } from '../converters/question/question-bullet-settings-to-bullets-styling';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type BulletsStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'list' }
>;

export const getBulletsStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);
  const bulletsStyling = questionBulletSettingsToBulletsStyling(question);

  const content: BulletsStaticContentSchema = {
    layout: slideLayout,
    type: 'list',
    heading: question.question,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    list: {
      items: question.choices.map((choice) => ({
        id: choice.id.toString(),
        content: choice.label,
      })),
    },
    textAlignment: {
      textHorizontalAlign: slideTextLayout.textHorizontalAlign,
      textVerticalAlign: slideTextLayout.textVerticalAlign,
    },
    textSizeOffset: slideTextLayout.textSizeOffset,
    listStyling: bulletsStyling,
  };

  return content;
};
