import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type WordcloudStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'word-cloud' }
>;

export const getWordcloudStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );

  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: WordcloudStaticContentSchema = {
    layout: slideLayout,
    type: 'word-cloud',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset,
    interactiveContentStyling: {
      answersPerResponse: question.max_nb_words,
    },
  };

  return content;
};
