import type { ReactionSettings } from '@core-api/editor/types/response';
import type { Series, VotingSeries } from '@mentimeter/http-clients';

export const seriesReactionsToSlideDeckReactionSettings = (
  series: Series | VotingSeries,
): ReactionSettings => {
  return {
    reactionsAllowed: series.reactions ?? [],
  };
};
