import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

type PowerPointStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'powerpoint' }
>;

export const getPowerPointStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: PowerPointStaticContentSchema = {
    layout: slideLayout,
    type: 'powerpoint',
    url: question.module_custom_data?.powerpoint_url ?? '',
    startingSlideNumber:
      question.module_custom_data?.starting_slide_number ?? 1,
    meta: question.title_meta,
    image: {
      ...questionToSlideImageReference(question),
    },
  };

  return content;
};
