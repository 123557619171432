import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { Question } from '@mentimeter/http-clients';
import {
  type CompatibilityInteractiveContent,
  type CompatibilitySlide,
} from '../../compatibility-types';
import {
  MULTIPLE_VOTE_POLICIES,
  SCORING_TIME_BASED_TYPE,
} from '../../constants';
import {
  correctAnswerModeToQuestionShowCorrect,
  responseModeToQuestionActive,
} from '../converters/interactive-content';
import { visualizationToQuestionSubType } from '../converters/interactive-content/visualization-to-question-subtype';
import { responseVisibilityToQuestionHideResults } from '../converters/slide';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import { getCachedChoicesProperty } from './utils/get-cached-choices-property';

const INTERACTIVE_QUESTION_PROPERTIES = [
  'question',
  'question_description',
  'multiple_votes',
  'range',
  'choices',
  'show_correct',
  'sub_type',
  'active',
  'time_based_scoring',
  'countdown_to_vote',
  'hide_results',
] as const satisfies Array<keyof Question>;

export type InteractiveQuestionProperty =
  (typeof INTERACTIVE_QUESTION_PROPERTIES)[number];

export const getInteractiveQuestionProperty = (
  slide: CompatibilitySlide,
  prop: InteractiveQuestionProperty,
) => {
  const interactiveContent = slide.interactiveContents?.[0];

  if (!interactiveContent) {
    throw new NoInteractiveContentError(slide.slideId);
  }

  switch (prop) {
    case 'question':
      return interactiveContent.title;
    case 'question_description':
      return interactiveContent.description;
    case 'multiple_votes': {
      return isMultipleVotes(interactiveContent);
    }
    case 'choices': {
      return getCachedChoicesProperty(slide, interactiveContent);
    }
    case 'range': {
      return interactiveContent.responseRange;
    }
    case 'show_correct':
      return getShowCorrect(interactiveContent);
    case 'sub_type': {
      return getQuestionSubType(interactiveContent);
    }
    case 'time_based_scoring': {
      return interactiveContent.scoring === SCORING_TIME_BASED_TYPE;
    }
    case 'countdown_to_vote': {
      return interactiveContent.countdown;
    }
    case 'active': {
      return responseModeToQuestionActive(interactiveContent.responseMode);
    }
    case 'hide_results':
      return responseVisibilityToQuestionHideResults(slide.responseVisibility);
    default:
      const error = new MentiError(
        `Unhandled interactive question property: \`${String(prop)}\``,
        {
          feature: 'compatibility-layer',
        },
      );
      captureException(error);

      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn(error.message);
      }
  }
};

export const isInteractiveQuestionProperty = (
  prop: keyof Question,
): prop is InteractiveQuestionProperty => {
  return INTERACTIVE_QUESTION_PROPERTIES.includes(
    prop as InteractiveQuestionProperty,
  );
};

const getQuestionSubType = (
  interactiveContent: CompatibilityInteractiveContent,
) => {
  if (
    interactiveContent.styling &&
    'visualization' in interactiveContent.styling
  ) {
    return visualizationToQuestionSubType(
      interactiveContent.styling.visualization,
    );
  }

  return null;
};

const getShowCorrect = (
  interactiveContent: CompatibilityInteractiveContent,
) => {
  if (
    interactiveContent.styling &&
    'correctAnswerMode' in interactiveContent.styling
  ) {
    return correctAnswerModeToQuestionShowCorrect(
      interactiveContent.styling.correctAnswerMode,
    );
  }

  return false;
};

const isMultipleVotes = (
  interactiveContent: CompatibilityInteractiveContent,
) => {
  return MULTIPLE_VOTE_POLICIES.includes(interactiveContent.responsePolicy);
};
