import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';

type PrioritizationStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'prioritization' }
>;

export const getPrioritizationStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );

  const content: PrioritizationStaticContentSchema = {
    layout: slideLayout,
    type: 'prioritization',
    meta: question.title_meta,
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset,
    styledTitle: question.question_styled ?? {},
  };

  return content;
};
