import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { WordcloudInteractiveContentStylingSchema } from '@mentimeter/schema/interactive-content-styling';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionWordcloudProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0];
  const interactiveContentsStyling =
    interactiveContents?.styling as WordcloudInteractiveContentStylingSchema;
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'word-cloud' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'max_nb_words':
        return interactiveContentsStyling.answersPerResponse;
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slidePublicKey);
};
