import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type MiroStaticContentSchema = Extract<StaticContentSchema, { type: 'miro' }>;

export const getMiroStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);
  const content: MiroStaticContentSchema = {
    type: 'miro',
    layout: slideLayout,
    meta: question.title_meta,
    heading: question.question,
    description: question.question_description,
    url: question.module_custom_data?.miro_url ?? '',
    showBoardToAudience: !!question.module_custom_data?.audience_board,
    image: questionToSlideImageReference(question),
    textSizeOffset: slideTextLayout.textSizeOffset,
  };

  return content;
};
