import type { ParticipationSettings } from '@core-api/editor/types/response';
import type { SeriesWithSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { PaceMode, Series } from '@mentimeter/http-clients';
import {
  PARTICIPATION_MODE_PRESENTATION,
  PARTICIPATION_MODE_SURVEY,
  PARTICIPATION_POLICY_NO_RESTRICTION,
  PARTICIPATION_POLICY_SINGLE_SUBMISSION,
} from '../../../constants';
import { seriesClosedForVotingToSlideDeckParticipationResponseMode } from './series-closed-for-voting-to-slide-deck-participation-response-mode';

export const seriesPaceToSlideDeckParticipationMode = (
  mode: PaceMode,
): ParticipationSettings['participationMode'] => {
  return mode === 'audience'
    ? PARTICIPATION_MODE_SURVEY
    : PARTICIPATION_MODE_PRESENTATION;
};

export const seriesVoteAgainEnabledToSlideDeckParticipationPolicy = (
  voteAgainEnabled: boolean,
): ParticipationSettings['participationPolicy'] => {
  return voteAgainEnabled
    ? PARTICIPATION_POLICY_NO_RESTRICTION
    : PARTICIPATION_POLICY_SINGLE_SUBMISSION;
};

export const seriesPaceToSlideDeckParticipationSettings = (
  series: Series | SeriesWithSlideDeck,
): ParticipationSettings => {
  const participationMode = seriesPaceToSlideDeckParticipationMode(
    series.pace.mode,
  );

  const participationPolicy =
    seriesVoteAgainEnabledToSlideDeckParticipationPolicy(
      series.vote_again_enabled,
    );

  const participationIdentityMode =
    series.participation_identity_mode ?? 'anonymous';

  const participationIdentityModePolicy =
    series.participation_identity_mode_policy ?? 'enabled';

  const participationAuthenticationMode =
    series.participation_authentication_mode ?? 'unauthenticated';

  const participationAuthenticationPolicy =
    series.participation_authentication_policy ?? 'unavailable';

  const participationResponseMode =
    seriesClosedForVotingToSlideDeckParticipationResponseMode(
      series.closed_for_voting,
    );

  return {
    participationKey: series.vote_key,
    participationExpiredResponsesDeletedAt:
      series.expired_responses_deleted_at ?? '',
    participationMode,
    participationPolicy,
    participationIdentityModePolicy,
    participationIdentityMode,
    participationAuthenticationMode,
    participationAuthenticationPolicy,
    participationResponseMode,
  };
};
