import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import { isContentType } from '../../utils/is-content-type';
import { MismatchedSlideTypePropertyError } from '../errors/mismatched-slide-type-property-error';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

const staticContentSchemaType: StaticContentSchema['type'] = 'list';
type BulletsStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'list' }
>;

export const getQuestionBulletsProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent;

  if (
    isContentType<BulletsStaticContentSchema>(
      staticContents,
      staticContentSchemaType,
    )
  ) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'question':
        return staticContents.heading;

      case 'choices':
        const choices = staticContents.list.items.map<
          Question['choices'][number]
        >(({ id, content }, index) => ({
          id: id as unknown as number,
          label: content,
          correct_answer: false,
          position: index + 1,
        }));
        const cacheKey = JSON.stringify(choices);
        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'choices',
          cacheKey,
          choices,
        );

      case 'numbered_list':
        return staticContents.listStyling.listStyle === 'numbered';

      case 'show_all_bullets':
        return staticContents.listStyling.listDisplayMode === 'simultaneous';

      default:
        return undefined;
    }
  }
  throw new MismatchedSlideTypePropertyError(
    staticContentSchemaType,
    target.staticContent.type,
  );
};
