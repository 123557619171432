import type { Series } from '@mentimeter/http-clients/edge';
import dynamic from 'next/dynamic';
import type { SlidePreviewSlideDeck } from '../types';
import { PlaceholderSlidePreview } from './PlaceholderSlidePreview';

export function getSlidePreview(data: Series | SlidePreviewSlideDeck) {
  return dynamic(() => import('../SlidePreview').then((m) => m.SlidePreview), {
    ssr: false,
    loading: () => PlaceholderSlidePreview({ data }),
  });
}
