import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

type ImageStaticContentSchema = Extract<StaticContentSchema, { type: 'image' }>;

export const getImageStaticContent = (question: Question) => {
  const content: ImageStaticContentSchema = {
    type: 'image',
    imagePosition:
      question.slide_image_type === 'image-frame' ? 'center' : 'background',
    caption: question.question,
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
  };

  return content;
};
