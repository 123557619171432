import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type ParagraphStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'paragraph' }
>;

export const getParagraphStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);
  const content: ParagraphStaticContentSchema = {
    // TODO: What is this in the old model?
    text: question.question_description,
    layout: slideLayout,
    type: 'paragraph',
    body: question.question_description,
    heading: question.question,
    meta: question.title_meta,
    image: {
      ...questionToSlideImageReference(question),
    },
    textAlignment: {
      textHorizontalAlign: slideTextLayout.textHorizontalAlign,
      textVerticalAlign: slideTextLayout.textVerticalAlign,
    },
    textSizeOffset: slideTextLayout.textSizeOffset,
  };

  return content;
};
