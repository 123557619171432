import type { ParticipationSettings } from '@core-api/editor/types/response';
import type { Series } from '@mentimeter/http-clients';
import {
  SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES,
  SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED,
} from '../../../constants';

export const seriesClosedForVotingToSlideDeckParticipationResponseMode = (
  closedForVoting: Series['closed_for_voting'],
): ParticipationSettings['participationResponseMode'] => {
  return closedForVoting
    ? SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED
    : SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_ACCEPTING_RESPONSES;
};
