import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { isContentType } from '../../utils/is-content-type';
import { MismatchedSlideTypePropertyError } from '../errors/mismatched-slide-type-property-error';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

const staticContentSchemaType: StaticContentSchema['type'] = 'big';
type BigStaticContentSchema = Extract<StaticContentSchema, { type: 'big' }>;

export const getQuestionBigProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent;

  if (
    isContentType<BigStaticContentSchema>(
      staticContents,
      staticContentSchemaType,
    )
  ) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'question':
        return staticContents.message;
      default:
        return undefined;
    }
  }

  throw new MismatchedSlideTypePropertyError(
    staticContentSchemaType,
    target.staticContent.type,
  );
};
