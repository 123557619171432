import React from 'react';
import { CheckIcon, CrossIcon, LoaderIcon } from '@mentimeter/ragnar-visuals';
import { Box } from '../box';
import { type IconButtonGenericBaseProps } from './IconButtonGeneric';
import { variants } from './IconContainer.variants';

type Size = 'large' | 'default' | 'compact';

interface IconContainerProps extends Pick<IconButtonGenericBaseProps, 'state'> {
  children: React.ReactNode;
  size?: Size;
}

export const IconContainer = ({
  children,
  size = 'default',
  state,
}: IconContainerProps) => {
  const iconContainerClasses = variants({ size });

  return (
    <Box as="span" className={iconContainerClasses}>
      {state === 'error' && <CrossIcon color="currentColor" />}
      {state === 'success' && <CheckIcon color="currentColor" />}
      {state === 'loading' && <LoaderIcon color="currentColor" />}
      {!state && children}
    </Box>
  );
};
