import type { LiveChatSettings } from '@core-api/editor/types/response';
import type { Series } from '@mentimeter/http-clients';
import { LIVE_CHAT_ENABLED } from '../../../constants';

export const liveChatSettingsToSeriesCfaProperties = (
  liveChatSettings: LiveChatSettings,
): Pick<Series, 'comments_enabled'> => {
  return {
    comments_enabled: liveChatSettings?.liveChatMode === LIVE_CHAT_ENABLED,
  };
};
