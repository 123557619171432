import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionShowCorrectToSlideCorrectAnswerMode } from '../converters/question/question-show-correct-to-slide-correct-answer-mode';
import {
  questionPercentDefaultToResponseCountDisplayMode,
  questionSubTypeToSlideVisualization,
} from '../converters/question';

type MultipleChoiceStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'multiple-choice' }
>;

export const getChoicesStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);

  const content: MultipleChoiceStaticContentSchema = {
    layout: slideLayout,
    type: 'multiple-choice',
    meta: question.title_meta,
    image: questionToSlideImageReference(question),
    styledTitle: question.question_styled ?? {},
    textSizeOffset: slideTextLayout.textSizeOffset,
    interactiveContentStyling: {
      visualization: questionSubTypeToSlideVisualization(
        question.sub_type,
      ) as MultipleChoiceStaticContentSchema['interactiveContentStyling']['visualization'],
      correctAnswerMode: questionShowCorrectToSlideCorrectAnswerMode(
        question.show_correct,
      ),
      responseCountDisplayMode:
        questionPercentDefaultToResponseCountDisplayMode(
          question.percent_default,
        ),
    },
  };

  return content;
};
