import React from 'react';
import type { Series } from '@mentimeter/http-clients/edge';
import { createPresentationSeriesCompatibilityLayer } from '@mentimeter/compatibility/presentation';
import { PreviewContainer } from '../components/PreviewContainer';
import {
  DEFAULT_SLIDE_PREVIEW_BACKGROUND,
  getThemeBackgroundColor,
} from '../utils/getThemeBackgroundColor';
import type { SlidePreviewSlideDeck } from '../types';

export function PlaceholderSlidePreview({
  data,
  currentQuestionId,
}: {
  data: Series | SlidePreviewSlideDeck;
  currentQuestionId?: string;
}) {
  const seriesWithSlideDeck = createPresentationSeriesCompatibilityLayer(data);

  if (!seriesWithSlideDeck) {
    return <PreviewContainer background={DEFAULT_SLIDE_PREVIEW_BACKGROUND} />;
  }

  const background = getThemeBackgroundColor(
    seriesWithSlideDeck,
    currentQuestionId,
  );

  return <PreviewContainer background={background} />;
}
