import { MentiError, type MentiErrorOptions } from '@mentimeter/errors/sentry';

export class NoInteractiveContentError extends MentiError {
  constructor(
    publicKey: string,
    options?: Partial<MentiErrorOptions> | undefined,
  ) {
    const message = `Slide with publicKey ${publicKey}} does not have interactive content`;
    super(message, {
      feature: 'compatibility-layer',
      ...options,
    });
    this.name = 'NoInteractiveContentError';
  }
}
