import {
  INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_ABSOLUTE,
  INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE,
  type InteractiveContentResponseCountValueDisplayMode,
} from '../../../constants';

export const questionPercentDefaultToResponseCountDisplayMode = (
  percentDefault: boolean,
): InteractiveContentResponseCountValueDisplayMode => {
  return percentDefault
    ? INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_PERCENTAGE
    : INTERACTIVE_CONTENT_RESPONSE_COUNT_DISPLAY_MODE_ABSOLUTE;
};
