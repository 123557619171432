import type { Layout } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';

type TextAlignmentSchema = Extract<
  StaticContentSchema,
  { textAlignment: any }
>['textAlignment'];

const slideHorizontalAlignmentToQuestionMap: Record<
  TextAlignmentSchema['textHorizontalAlign'],
  Layout['alignment_x']
> = {
  left: 0,
  center: 1,
  right: 2,
};

const slideVerticalAlignmentToQuestionMap: Record<
  TextAlignmentSchema['textVerticalAlign'],
  Layout['alignment_y']
> = {
  top: 0,
  center: 1,
  bottom: 2,
};

const getQuestionAlignmentY = (textAlignment?: TextAlignmentSchema) => {
  const slideTextVerticalAlign = textAlignment?.textVerticalAlign ?? 'center';

  return slideTextVerticalAlign
    ? slideVerticalAlignmentToQuestionMap[slideTextVerticalAlign]
    : 1;
};

const getQuestionAlignmentX = (textAlignment?: TextAlignmentSchema) => {
  const slideTextHorizontalAlign =
    textAlignment?.textHorizontalAlign ?? 'center';

  return slideTextHorizontalAlign
    ? slideHorizontalAlignmentToQuestionMap[slideTextHorizontalAlign]
    : 1;
};

export const textAlignmentToQuestionTextAlignment = (
  textAlignment?: TextAlignmentSchema,
) => {
  return {
    alignmentX: getQuestionAlignmentX(textAlignment),
    alignmentY: getQuestionAlignmentY(textAlignment),
  };
};
