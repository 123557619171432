import type { Question } from '@mentimeter/http-clients';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionFreeTextProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question_description':
      return '';
    default:
      return undefined;
  }
};
