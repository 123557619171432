import type { ParticipationSettings as VotingParticipationSettings } from '@core-api/audience/types/response';
import type { ParticipationSettings as EditorParticipationSettings } from '@core-api/editor/types/response';
import type { ParticipationSettings as PresentationParticipationSettings } from '@core-api/presentation/types/response';
import type { Series } from '@mentimeter/http-clients';

export const participationSettingsToSeriesParticipationIdentityMode = (
  participationSettings:
    | EditorParticipationSettings
    | PresentationParticipationSettings
    | VotingParticipationSettings
    | undefined,
): Series['participation_identity_mode'] => {
  return participationSettings?.participationIdentityMode ?? 'anonymous';
};
