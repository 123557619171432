import type { ParticipationSettings as VotingParticipationSettings } from '@core-api/audience/types/response';
import type { ParticipationSettings as EditorParticipationSettings } from '@core-api/editor/types/response';
import type { ParticipationSettings as PresentationParticipationSettings } from '@core-api/presentation/types/response';
import type { Series } from '@mentimeter/http-clients';
import { PARTICIPATION_POLICY_NO_RESTRICTION } from '../../../constants';

export const participationIdentityModeSettingsToSeriesVoteAgainEnabled = (
  participationSettings:
    | EditorParticipationSettings
    | PresentationParticipationSettings
    | VotingParticipationSettings
    | undefined,
): Series['vote_again_enabled'] => {
  return (
    participationSettings?.participationPolicy ===
    PARTICIPATION_POLICY_NO_RESTRICTION
  );
};
