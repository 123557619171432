import type { ParticipationSettings as VotingParticipationSettings } from '@core-api/audience/types/response';
import type { ParticipationSettings as EditorParticipationSettings } from '@core-api/editor/types/response';
import type { ParticipationSettings as PresentationParticipationSettings } from '@core-api/presentation/types/response';
import type { Series } from '@mentimeter/http-clients';
import { PARTICIPATION_MODE_SURVEY } from '../../../constants';

export const participationModeToSeriesPaceMode = (
  participationSettings:
    | EditorParticipationSettings
    | PresentationParticipationSettings
    | VotingParticipationSettings
    | undefined,
): Series['pace']['mode'] => {
  return participationSettings?.participationMode === PARTICIPATION_MODE_SURVEY
    ? 'audience'
    : 'presenter';
};
