import type { OwnershipSettings } from '@core-api/editor/types/response';
import type { Series } from '@mentimeter/http-clients';
import {
  COLLABORATION_MODE_COLLABORATIVE,
  COLLABORATION_MODE_NON_COLLABORATIVE,
} from '../../../constants';

const seriesCoEditToSlideDeckCollaborationMode = (
  series: Series,
): OwnershipSettings['collaborationMode'] => {
  return series.co_edited
    ? COLLABORATION_MODE_COLLABORATIVE
    : COLLABORATION_MODE_NON_COLLABORATIVE;
};

export const seriesOwnershipToSlideDeckOwnershipSettings = (
  series: Series,
): OwnershipSettings => {
  return {
    ownerId: series.owner_id,
    workspaceId: series.workspace_id,
    collaborationMode: seriesCoEditToSlideDeckCollaborationMode(series),
    folderId: series.folder_id,
    folderName: series.folder_name,
    resultsSharing: series.results_sharing || 'on',
  };
};
