import type { ModuleId } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/audience/types/response';

export const ModuleIdByType: Record<StaticContentSchema['type'], ModuleId> = {
  'free-text': 'free-text',
  'google-slides': 'google-slides',
  'guess-the-number': 'numerical-question',
  'multiple-choice': 'choices',
  'open-ended': 'open',
  'pin-on-image': 'pin-on-image',
  'questions-from-audience': 'qfa',
  'quick-form': 'metadata',
  'quiz-choice': 'quiz-choices',
  'quiz-open': 'quiz-open',
  'word-cloud': 'wordcloud',
  big: 'big',
  document: 'document',
  heading: 'heading',
  image: 'image',
  instructions: 'instructions',
  leaderboard: 'quiz-leaderboard',
  list: 'bullets',
  miro: 'miro',
  number: 'number',
  paragraph: 'paragraph',
  powerpoint: 'powerpoint',
  prioritization: '100-points',
  quote: 'quote',
  ranking: 'ranking',
  rating: 'rating',
  scales: 'scales',
  video: 'video',
};

export const typeToModuleId = (
  staticContentType: StaticContentSchema['type'],
) => {
  return ModuleIdByType[staticContentType];
};
