import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';

type BulletsStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'list' }
>;

export const questionBulletSettingsToBulletsStyling = (
  question: Question,
): BulletsStaticContentSchema['listStyling'] => ({
  listDisplayMode: question.show_all_bullets ? 'simultaneous' : 'sequential',
  listStyle: question.numbered_list ? 'numbered' : 'bullet',
});
