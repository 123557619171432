import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionLayoutToSlideLayout } from './question-layout-to-slide-layout';

type ImageReferenceSchema = StaticContentSchema['image'];

export const questionToSlideImageReference = (
  question: Question,
): ImageReferenceSchema => {
  const [, layoutPosition, inset] = questionLayoutToSlideLayout(
    question.layout,
  );

  return {
    imageLayoutPosition: layoutPosition,
    imageInset: inset,
    ...(question.image?.s3_key ? { imagePath: question.image?.s3_key } : {}),
  };
};
