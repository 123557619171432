import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionQuoteProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'quote' }
  >;

  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question':
      return staticContents.quote;
    case 'question_description':
      return staticContents.author;
    default:
      return undefined;
  }
};
