import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { QuestionSubType } from '@mentimeter/http-clients';
import type { Visualization } from '@mentimeter/schema/partials';

export const QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION: Partial<
  Record<Exclude<QuestionSubType, null> | '2x2', Visualization>
> = {
  dots: 'dots',
  choices: 'bar_chart',
  donut: 'donut_chart',
  pie: 'pie_chart',
  flow: 'flowing_grid',
  open: 'speech_bubbles',
  spider: 'spider-chart',
  scales: 'slider-chart',
  'two-values': 'two-values',
  'four-values': 'four-values',
  '2x2': 'two-values',
};

// 2x2 is a legacy subtype that can exist on the rating slide,
// defaulting to the two-values visualization so that the frontend does not break.
export const questionSubTypeToSlideVisualization = (
  subType: QuestionSubType | '2x2',
) => {
  if (!subType) {
    return null;
  }

  const visualization = QUESTION_SUBTYPE_TO_SLIDE_VISUALIZATION[subType];

  if (!visualization) {
    const error = new MentiError(
      `Unhandled subtype when converting subtype to visualization: \`${String(subType)}\``,
      {
        feature: 'compatibility-layer',
      },
    );
    captureException(error);

    return null;
  }

  return visualization;
};
