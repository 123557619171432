import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../../utils/get-or-set-cache';
import type { CompatibilitySlide } from '../../../compatibility-types';
import { layoutToQuestionLayout } from './layout-to-question-layout';

export const getQuestionLayout = (slide: CompatibilitySlide | VotingSlide) => {
  const { staticContent } = slide;

  // TODO: static content should not be undefined here based on types. Leaving this check for now to avoid unintended consequences.
  if (!staticContent) {
    return undefined;
  }

  if (!('layout' in staticContent)) {
    return undefined;
  }

  const textAlignment =
    'textAlignment' in staticContent ? staticContent.textAlignment : undefined;

  const fontSizeOffset =
    'textSizeOffset' in staticContent ? staticContent.textSizeOffset : 0;

  const questionLayout = layoutToQuestionLayout({
    layout: staticContent.layout,
    layoutPosition: staticContent.image.imageLayoutPosition,
    inset: Boolean(staticContent.image.imageInset),
    textAlignment,
    fontSizeOffset,
  });

  const cacheKey = JSON.stringify(questionLayout);

  return getOrSetCache(
    slide as WithCache<CompatibilitySlide>,
    'layout',
    cacheKey,
    questionLayout,
  );
};
