import type { Question } from '@mentimeter/http-clients';
import type { Slide as PresentationSlide } from '@mentimeter/presentation-schema/api-types-overrides';
import { getCommonSlideProperty } from '../../../forward-compatibility/get-common-slide-property';

export const mapPresentationSlidePropertyToQuestionProperty = (
  question: Question,
  key: keyof PresentationSlide,
) => {
  switch (key) {
    default:
      return getCommonSlideProperty(question, key);
  }
};
