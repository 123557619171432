import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionSubTypeToSlideVisualization } from '../converters/question';

type OpenEndedStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'open-ended' }
>;

export const getOpenEndedStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);
  const content: OpenEndedStaticContentSchema = {
    layout: slideLayout,
    type: 'open-ended',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset: slideTextLayout.textSizeOffset,
    interactiveContentStyling: {
      visualization: questionSubTypeToSlideVisualization(
        question.sub_type,
      ) as OpenEndedStaticContentSchema['interactiveContentStyling']['visualization'],
    },
  };

  return content;
};
