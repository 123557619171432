import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type InstructionsStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'instructions' }
>;

export const getInstructionsStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: InstructionsStaticContentSchema = {
    type: 'instructions',
    meta: question.title_meta,
    layout: slideLayout,
    styledTitle: question.question_styled,
    image: questionToSlideImageReference(question),
  };

  return content;
};
