import React, { Suspense } from 'react';
import { Skeleton } from '@mentimeter/ragnar-ui/skeleton';
import { ErrorBoundary } from '@mentimeter/errors/ErrorBoundary';
import { PreviewContainer } from './PreviewContainer';

export function SlidePreviewBoundary({
  children,
  context,
}: { children: React.ReactNode; context: string }) {
  return (
    <ErrorBoundary
      feature="slide-preview"
      errorMessage={`Could not load presentation preview (${context})`}
      fallback={<PreviewContainer background="neutral" />}
    >
      <Suspense
        fallback={
          <PreviewContainer>
            <Skeleton height="100%" variant="image" borderRadius="lg" />
          </PreviewContainer>
        }
      >
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}
