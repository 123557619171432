import type { PresentationTimer } from '@core-api/presentation/types/response';
import type { Series } from '@mentimeter/http-clients';

export const seriesPresentationTimeToPresentationTimer = (
  series: Series,
): PresentationTimer => {
  if (!series.presentation_time) {
    return {
      presentationStartedAt: 0,
    };
  }
  return { presentationStartedAt: series.presentation_time };
};
