import type { Series } from '@mentimeter/http-clients';
import type { CompatibilitySlideDeck } from '../compatibility-types';
import { getOrSetCache, type WithCache } from '../utils/get-or-set-cache';
import { createPresentationSlideCompatibilityLayer } from '../applications/presentation';
import { seriesCfaPropertiesToLiveChatSettings } from './converters/series/series-cfa-properties-to-live-chat-settings';
import { seriesQaPropertiesToQaSettings } from './converters/series/series-qa-properties-to-qa-settings';
import { seriesSegmentationToSlideDeckSegmentation } from './converters/series/series-segmentation-to-slidedeck-segmentation';
import { seriesReactionsToSlideDeckReactionSettings } from './converters/series/series-reactions-to-slidedeck-reaction-settings';
import { seriesLanguageToLanguageSettings } from './converters/series/series-language-to-language-settings';
import { seriesProfanityLangToProfanitySettings } from './converters/series/series-profanity-lang-to-profanity-settings';
import { seriesMusicUrlToMusicSettings } from './converters/series/series-music-url-to-music-settings';
import { seriesPaceToSlideDeckParticipationSettings } from './converters/series/series-pace-to-slidedeck-participation-settings';
import { seriesOwnershipToSlideDeckOwnershipSettings } from './converters/series/series-ownership-to-slidedeck-ownership-settings';
import { seriesPresentationTimeToPresentationTimer } from './converters/series/series-presentation-time-to-presentation-timer';

export function getUnmigratedSlideDeckProperty(
  target: Series,
  key: keyof Series | keyof CompatibilitySlideDeck | '__isProxy' | '__target',
) {
  // TODO MX-483 remove cast to as any after we introduce property mappings for each application
  switch (key as any) {
    case 'slideDeckPublicKey':
      return target.id ?? '';

    case 'name':
      return target.name;

    case 'slideDeckSegmentations':
      const segmentations = seriesSegmentationToSlideDeckSegmentation(
        target.segmentations,
      );

      const cacheKey = JSON.stringify(segmentations);
      return getOrSetCache(
        target as WithCache<Series>,
        'segmentations',
        cacheKey,
        segmentations,
      );

    case 'themeId':
      return target.theme_id;

    case 'qaSettings':
      const qaSettings = seriesQaPropertiesToQaSettings(target as Series);
      return getOrSetCache(
        target as WithCache<Series>,
        'qaSettings',
        JSON.stringify(qaSettings),
        qaSettings,
      );

    case 'liveChatSettings':
      const liveChatSettings = seriesCfaPropertiesToLiveChatSettings(
        target as Series,
      );
      return getOrSetCache(
        target as WithCache<Series>,
        'liveChatSettings',
        JSON.stringify(liveChatSettings),
        liveChatSettings,
      );

    case 'reactionSettings':
      const reactionSettings = seriesReactionsToSlideDeckReactionSettings(
        target as Series,
      );
      return getOrSetCache(
        target as WithCache<Series>,
        'reactionSettings',
        JSON.stringify(reactionSettings),
        reactionSettings,
      );

    case 'languageSettings':
      const languageSettings = seriesLanguageToLanguageSettings(
        target as Series,
      );
      return getOrSetCache(
        target as WithCache<Series>,
        'languageSettings',
        JSON.stringify(languageSettings),
        languageSettings,
      );

    case 'legacyThemeSettings':
      const legacyThemeSettings = target.theme;

      return getOrSetCache(
        target as WithCache<Series>,
        'legacyThemeSettings',
        JSON.stringify(legacyThemeSettings),
        legacyThemeSettings,
      );

    case 'profanitySettings':
      const profanitySettings = seriesProfanityLangToProfanitySettings(
        target as Series,
      );
      return getOrSetCache(
        target as WithCache<Series>,
        'profanitySettings',
        JSON.stringify(profanitySettings),
        profanitySettings,
      );

    case 'musicSettings':
      const musicSettings = seriesMusicUrlToMusicSettings(target as Series);

      return getOrSetCache(
        target as WithCache<Series>,
        'musicSettings',
        JSON.stringify(musicSettings),
        musicSettings,
      );

    case 'presentationTimer':
      const presentationTimer = seriesPresentationTimeToPresentationTimer(
        target as Series,
      );
      return getOrSetCache(
        target as WithCache<Series>,
        'presentationTimer',
        JSON.stringify(presentationTimer),
        presentationTimer,
      );

    case 'participationSettings':
      const participationSettings = seriesPaceToSlideDeckParticipationSettings(
        target as Series,
      );

      return getOrSetCache(
        target as WithCache<Series>,
        'participationSettings',
        JSON.stringify(participationSettings),
        participationSettings,
      );

    case 'slides': {
      const slides = target.questions.map((question) => {
        return createPresentationSlideCompatibilityLayer(question);
      });

      return getOrSetCache(
        target as WithCache<Series>,
        'slides',
        JSON.stringify(slides),
        slides,
      );
    }

    case 'ownershipSettings':
      const ownershipSettings = seriesOwnershipToSlideDeckOwnershipSettings(
        target as Series,
      );

      return getOrSetCache(
        target as WithCache<Series>,
        'ownershipSettings',
        JSON.stringify(ownershipSettings),
        ownershipSettings,
      );

    // TODO: MX-484 we did not have this before, but we need to add it.
    // Add the conversion when the slide compatibility layer is refactored.
    // We should be able to just use createSlideCompatibilityLayer(slide) here.
    // case 'slides':

    default:
      return target[key as keyof Series];
  }
}
