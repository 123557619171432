import type { MusicSettings } from '@core-api/editor/types/response';
import type { Series } from '@mentimeter/http-clients';

export const seriesMusicUrlToMusicSettings = (
  series: Series,
): MusicSettings => {
  if (!series.music_url) {
    return {
      playbackPolicy: 'disabled',
      url: '',
    };
  }

  return {
    playbackPolicy: 'quiz-enabled',
    url: series.music_url ?? '',
  };
};
