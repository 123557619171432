import React from 'react';
import type { ReactNode } from 'react';
import { PopoverTrigger } from '@mentimeter/ragnar-ui/popover';
import { CaptureInteraction } from './CaptureInteraction';

export function PaywallPopoverTrigger({
  trigger,
  children,
}: {
  trigger: () => void;
  children: ReactNode;
}) {
  return (
    <PopoverTrigger>
      <CaptureInteraction trigger={trigger}>{children}</CaptureInteraction>
    </PopoverTrigger>
  );
}
