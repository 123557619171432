import type {
  InteractiveContentWithStyling,
  QuestionWithSlide,
} from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { RatingInteractiveContentStylingSchema as Styling } from '@mentimeter/schema/interactive-content-styling';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import { RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES } from '../../constants';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import {
  ratingAxisLabelsToQuestionDimensions,
  ratingGridToQuestionGrid,
} from '../converters/interactive-content';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionRatingProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0] as
    | InteractiveContentWithStyling<'rating'>
    | undefined;
  const interactiveContentsStyling = interactiveContents?.styling as Styling;

  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'rating' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'hide_skip':
        return (
          interactiveContents.responsePolicy ===
          RESPONSE_POLICY_SINGLE_RESPONSE_ALL_CHOICES
        );
      case 'dimensions':
        const dimensions = ratingAxisLabelsToQuestionDimensions({
          xAxisLabels: interactiveContentsStyling.xAxisLabels,
          yAxisLabels: interactiveContentsStyling.yAxisLabels,
        });

        const cacheKey = JSON.stringify(dimensions);
        return getOrSetCache(
          target as WithCache<QuestionWithSlide>,
          'dimensions',
          cacheKey,
          dimensions,
        );
      case 'range':
        return interactiveContents.responseRange;
      case 'grid':
        const grid = ratingGridToQuestionGrid(interactiveContents);

        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'grid',
          JSON.stringify(grid),
          grid,
        );
      default:
        return undefined;
    }
  }
};
