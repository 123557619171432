import type { QuestionSlideType } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';

export const SlideTypeByType: Record<
  StaticContentSchema['type'],
  QuestionSlideType
> = {
  'free-text': 'free-text',
  'google-slides': 'google-slides',
  'guess-the-number': 'heading',
  'multiple-choice': 'heading',
  'open-ended': 'heading',
  'pin-on-image': 'heading',
  'questions-from-audience': 'heading',
  'quick-form': 'heading',
  'quiz-choice': 'heading',
  'quiz-open': 'heading',
  'word-cloud': 'heading',
  big: 'big',
  document: 'document',
  heading: 'heading',
  image: 'image',
  instructions: 'instructions',
  leaderboard: 'heading',
  list: 'bullets',
  miro: 'miro',
  number: 'number',
  paragraph: 'paragraph',
  powerpoint: 'powerpoint',
  prioritization: 'heading',
  quote: 'quote',
  ranking: 'heading',
  rating: 'heading',
  scales: 'heading',
  video: 'video',
};

export const typeToSlideType = (
  staticContentType: StaticContentSchema['type'],
) => {
  return SlideTypeByType[staticContentType];
};
