import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';

type QuestionsFromAudienceStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'questions-from-audience' }
>;

export const getQuestionsFromAudienceStaticContentSchemaStaticContent = (
  question: Question,
) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: QuestionsFromAudienceStaticContentSchema = {
    layout: slideLayout,
    type: 'questions-from-audience',
    styledTitle: question.question_styled ?? {},
    description: question.question_description,
    meta: question.title_meta,
    image: {
      ...questionToSlideImageReference(question),
    },
  };

  return content;
};
