import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionMiroProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'miro' }
  >;

  switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
    case 'question':
      return staticContents.heading;
    case 'question_description':
      return staticContents.description;
    case 'module_custom_data':
      return {
        miro_url: staticContents.url,
        audience_board: staticContents.showBoardToAudience,
      };
    default:
      return undefined;
  }
};
