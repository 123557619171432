import type { LiveChatSettings } from '@core-api/editor/types/response';
import type { Series, VotingSeries } from '@mentimeter/http-clients';
import { LIVE_CHAT_DISABLED, LIVE_CHAT_ENABLED } from '../../../constants';

export const seriesCfaPropertiesToLiveChatSettings = (
  series: Series | VotingSeries,
): LiveChatSettings => {
  return {
    liveChatMode: series.comments_enabled
      ? LIVE_CHAT_ENABLED
      : LIVE_CHAT_DISABLED,
  };
};
