import { MentiError, captureException } from '@mentimeter/errors/sentry';
import {
  Channels,
  SeriesPrivateEvents,
  usePublish,
} from '@mentimeter/realtime';
import React from 'react';
import type { SWRConfiguration } from 'swr';
import useSWR, { useSWRConfig, unstable_serialize as serialize } from 'swr';
import type { SWRInfiniteKeyedMutator } from 'swr/infinite';

export interface UseDataResponse<T> {
  data: T | undefined;
  lazyData: () => T | undefined;
  mutate: SWRInfiniteKeyedMutator<T>;
  error: any;
  revalidate: () => Promise<{ responseForBackwardCompat: T | undefined }>;
}

export const useData = <T>(
  {
    cacheKey,
    fetcher,
    seriesId,
    skip,
  }: {
    cacheKey: string | Array<string | number> | undefined;
    seriesId: string | undefined;
    fetcher: () => Promise<T>;
    skip?: boolean;
  },
  config?: SWRConfiguration<T>,
): UseDataResponse<T> => {
  const {
    data,
    mutate: originalMutate,
    error,
  } = useSWR(skip ? null : cacheKey, fetcher, config);
  const { cache, fallback } = useSWRConfig();
  const lazyData = React.useCallback((): T | undefined => {
    const key = cacheKey ? serialize(cacheKey) : null;
    return key ? (cache.get(key)?.data ?? fallback[key]) : null;
  }, [cache, cacheKey, fallback]);

  const publish = usePublish(
    seriesId ? { channel: Channels.SERIES_PRIVATE, value: seriesId } : null,
  );

  const mutate = React.useCallback<SWRInfiniteKeyedMutator<T>>(
    async (...args) => {
      try {
        if (cacheKey) {
          publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
            cacheKey: serialize(cacheKey),
          });
        }
        // Explicitly cast `mutate` to avoid TypeScript conflicts
        return await (originalMutate as SWRInfiniteKeyedMutator<T>)(...args);
      } catch (error) {
        captureException(
          new MentiError('Could not mutate, SWR bug?', {
            feature: 'live',
          }),
        );
        return undefined;
      }
    },
    [cacheKey, originalMutate, publish],
  );

  return {
    data,
    lazyData,
    error,
    mutate,
    revalidate: React.useCallback(async () => {
      const responseForBackwardCompat = await mutate<T>();
      return { responseForBackwardCompat };
    }, [mutate]),
  };
};
