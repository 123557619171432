import { MentiError } from '@mentimeter/errors/sentry';
import type { Layout } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { getGridSize } from '../../../utils/get-grid-size';
import { textAlignmentToQuestionTextAlignment } from './text-alignment-to-question-text-alignment';

type LayoutSchema = Extract<StaticContentSchema, { layout: any }>['layout'];
type LayoutPositionSchema = StaticContentSchema['image']['imageLayoutPosition'];
type TextAlignmentSchema = Extract<
  StaticContentSchema,
  { textAlignment: any }
>['textAlignment'];
type TextFontSizeOffsetSchema = Extract<
  StaticContentSchema,
  { textSizeOffset: any }
>['textSizeOffset'];

const possibleSizes = [0, 0.1, 0.25, 0.33, 0.5, 0.66];

const getQuestionLayoutSize = (
  layout: LayoutSchema,
  layoutPosition: LayoutPositionSchema,
): Layout['size'] => {
  const gridSize = getGridSize(layout);
  const sizeInGridUnits = layoutPosition.end - layoutPosition.start + 1;
  const sizeInGridUnitsAsPercentage = sizeInGridUnits / gridSize;

  // Return closest match from possibleSizes
  const match = possibleSizes.reduce((prev, curr) => {
    return Math.abs(curr - sizeInGridUnitsAsPercentage) <
      Math.abs(prev - sizeInGridUnitsAsPercentage)
      ? curr
      : prev;
  }, 0);

  return `${match * 100}%` as Layout['size'];
};

interface LayoutToQuestionLayoutArgs {
  layout: LayoutSchema;
  layoutPosition: LayoutPositionSchema;
  inset: boolean;
  textAlignment?: TextAlignmentSchema | undefined;
  fontSizeOffset: TextFontSizeOffsetSchema;
}

export const layoutToQuestionLayout = ({
  layout,
  layoutPosition: layoutPositionFromArgs,
  inset,
  textAlignment,
  fontSizeOffset,
}: LayoutToQuestionLayoutArgs): Layout => {
  const layoutPosition = layoutPositionFromArgs ?? {
    start: getGridSize(layout) - 2,
    end: getGridSize(layout),
  };

  const legacyLayout: Layout = {
    type: 'default',
    font_size_offset: 0,
    alignment_x: 1,
    alignment_y: 1,
    size: '25%',
  };

  const { alignmentX: questionAlignmentX, alignmentY: questionAlignmentY } =
    textAlignmentToQuestionTextAlignment(textAlignment);

  const size = getQuestionLayoutSize(layout, layoutPosition);
  const gridSize = getGridSize(layout);

  if (layout.snapDirection === 'y') {
    return {
      ...legacyLayout,
      type: layoutPosition.start === 1 ? 'top' : 'bottom',
      size,
      font_size_offset: fontSizeOffset,
      alignment_x: questionAlignmentX,
      alignment_y: questionAlignmentY,
    };
  }

  if (
    layout.snapDirection === 'x' &&
    layoutPosition.start === 1 &&
    layoutPosition.end === gridSize
  ) {
    return {
      ...legacyLayout,
      type: 'full-bg',
      font_size_offset: fontSizeOffset,
      alignment_x: questionAlignmentX,
      alignment_y: questionAlignmentY,
    };
  }

  if (
    layout.snapDirection === 'x' &&
    layoutPosition.start === 1 &&
    layoutPosition.end < gridSize
  ) {
    return {
      ...legacyLayout,
      type: inset ? 'side-by-side' : 'left',
      size,
      font_size_offset: fontSizeOffset,
      alignment_x: questionAlignmentX,
      alignment_y: questionAlignmentY,
    };
  }

  if (layout.snapDirection === 'x' && layoutPosition.start > 1) {
    return {
      ...legacyLayout,
      type: inset ? 'side-by-side-right' : 'right',
      size,
      font_size_offset: fontSizeOffset,
      alignment_x: questionAlignmentX,
      alignment_y: questionAlignmentY,
    };
  }

  if (
    layout.snapDirection === 'x' &&
    layoutPosition.start === 0 &&
    layoutPosition.end === 0
  ) {
    return {
      ...legacyLayout,
      type: 'default',
      font_size_offset: fontSizeOffset,
      alignment_x: questionAlignmentX,
      alignment_y: questionAlignmentY,
    };
  }

  throw new MentiError('Could not convert layout to legacy layout', {
    feature: 'compatibility-layer',
  });
};
