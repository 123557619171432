import type { Question, QuestionField } from '@mentimeter/http-clients';
import type { QuickFormInteractiveContentChoicesStylingSchema as ChoicesStyling } from '@mentimeter/schema/interactive-content-choices-styling';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import { getOrSetCache, type WithCache } from '../../utils/get-or-set-cache';
import { MismatchedSlideTypePropertyError } from '../errors/mismatched-slide-type-property-error';
import type { CompatibilitySlide } from '../../compatibility-types';
import type { CommonQuestionProperty } from './get-common-question-property';

const staticContentSchemaType: StaticContentSchema['type'] = 'quick-form';

export const getQuestionQuickFormProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents?.[0];

  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'quick-form' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'fields': {
        const fields = interactiveContents.choices.map<QuestionField>(
          (choice, i) => {
            const styling = choice.styling as ChoicesStyling;
            return {
              position: i,
              id: choice.legacyQuestionMetadataFieldId ?? 0,
              label: choice.title,
              type: styling.subType,
              options: styling.options ?? [],
              is_required: styling.isRequired,
              delete: undefined,
            };
          },
        );

        const cacheKey = JSON.stringify(fields);
        return getOrSetCache(
          target as WithCache<CompatibilitySlide>,
          'fields',
          cacheKey,
          fields,
        );
      }
      default:
        return undefined;
    }
  }
  throw new MismatchedSlideTypePropertyError(
    staticContentSchemaType,
    target.staticContent.type,
  );
};
