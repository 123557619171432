import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Question } from '@mentimeter/http-clients';
import type {
  InteractiveContent as VotingInteractiveContent,
  Slide as VotingSlide,
} from '@mentimeter/voting-schema/api-types-overrides';
import type {
  CompatibilityInteractiveContent,
  CompatibilitySlide,
} from '../../compatibility-types';
import { VOTE_ON_RESPONSE_ALLOW } from '../../constants';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

const getMultipleVotesOnAnswers = (
  interactiveContent:
    | CompatibilityInteractiveContent
    | VotingInteractiveContent,
) => {
  const maxEntriesPerResponse =
    interactiveContent.voteSettings?.maxEntriesPerResponse;
  return (
    typeof maxEntriesPerResponse === 'undefined' ||
    maxEntriesPerResponse === null
  );
};

const getMaxVotes = (
  interactiveContent:
    | CompatibilityInteractiveContent
    | VotingInteractiveContent,
) => {
  if (!interactiveContent.voteSettings?.maxEntries) {
    return 3;
  }

  return interactiveContent.voteSettings.maxEntries;
};

export const getQuestionOpenEndedProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const interactiveContents = target.interactiveContents[0];
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'open-ended' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContents) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'multiple_votes_on_answers': {
        return getMultipleVotesOnAnswers(interactiveContents);
      }
      case 'max_votes': {
        return getMaxVotes(interactiveContents);
      }
      case 'voting_on_answers': {
        return (
          interactiveContents.voteSettings?.voteOnResponsePolicy ===
          VOTE_ON_RESPONSE_ALLOW
        );
      }
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slidePublicKey);
};
