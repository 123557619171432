import type React from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Dark } from '@mentimeter/ragnar-ui/themes';
import { Markdown } from '@mentimeter/ragnar-ui/markdown';
import {
  PopoverBody,
  PopoverContainer,
  PopoverHeader,
  PopoverRoot,
} from '@mentimeter/ragnar-ui/popover';
import { Text } from '@mentimeter/ragnar-ui/text';
import { PaywallPopoverTrigger } from '../../triggers/PaywallPopoverTrigger';

export function RoleUpgradeDisabledPopoverContent({
  width = '300px',
  open,
  onOpenChange,
  title,
  description,
  children,
  message,
}: {
  width?: string;
  title: string;
  description: string;
  children: React.ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  message: string;
}) {
  return (
    <PopoverRoot modal open={open} onOpenChange={onOpenChange}>
      <PaywallPopoverTrigger trigger={() => onOpenChange(true)}>
        {children}
      </PaywallPopoverTrigger>
      <PopoverContent
        title={title}
        description={description}
        customMessage={message}
        width={width}
      />
    </PopoverRoot>
  );
}

export function PopoverContent({
  title,
  description,
  customMessage,
  width,
}: {
  title: string;
  description: string;
  customMessage: string;
  width: string;
}) {
  return (
    <PopoverContainer
      data-testid="request-role-upgrade-popover-disabled"
      width={width}
    >
      <Dark>
        <Box bg="surface">
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>
            <Text mb={2}>{description}</Text>
            <Box color="textWeak" mb={2}>
              <Markdown>{customMessage}</Markdown>
            </Box>
          </PopoverBody>
        </Box>
      </Dark>
    </PopoverContainer>
  );
}
