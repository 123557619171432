import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionShowCorrectToSlideCorrectAnswerMode } from '../converters/question/question-show-correct-to-slide-correct-answer-mode';

type PinOnImageStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'pin-on-image' }
>;

export const getPinOnImageStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const slideTextLayout = questionTextLayoutToSlideTextLayout(question.layout);
  const content: PinOnImageStaticContentSchema = {
    layout: slideLayout,
    image: {
      ...questionToSlideImageReference(question),
    },
    type: 'pin-on-image',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    textSizeOffset: slideTextLayout.textSizeOffset,
    interactiveContentStyling: {
      correctAnswerMode: questionShowCorrectToSlideCorrectAnswerMode(
        question.show_correct,
      ),
    },
  };

  return content;
};
