import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionDimensionsToSlideRatingAxisLabels } from '../converters/question/question-dimensions-to-slide-rating-axis-labels';
import { questionSubTypeToSlideVisualization } from '../converters/question';

type RatingStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'rating' }
>;

type InteractiveContentStyling =
  RatingStaticContentSchema['interactiveContentStyling'];

export const getRatingStaticContent = (question: Question) => {
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );

  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const axisLabels = questionDimensionsToSlideRatingAxisLabels(
    question.dimensions,
  );

  const content: RatingStaticContentSchema = {
    layout: slideLayout,
    type: 'rating',
    meta: question.title_meta,
    styledTitle: question.question_styled ?? {},
    image: {
      ...questionToSlideImageReference(question),
    },
    textSizeOffset,
    interactiveContentStyling: {
      visualization: questionSubTypeToSlideVisualization(
        question.sub_type,
      ) as InteractiveContentStyling['visualization'],
      xAxisLabels: axisLabels.xAxisLabels,
      yAxisLabels: axisLabels.yAxisLabels,
      // FIXME [MX] MX-665, which will also add gridImageRotation
      gridImageKey: question.image?.id as string,
      gridImagePath: question.image?.url as string,
    },
  };

  return content;
};
