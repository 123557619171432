import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';

type DocumentStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'document' }
>;

export const getDocumentStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);

  const content: DocumentStaticContentSchema = {
    type: 'document',
    meta: question.title_meta,
    layout: slideLayout,
    heading: question.question,
    image: questionToSlideImageReference(question),
  };

  return content;
};
