import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import { questionTextLayoutToSlideTextLayout } from '../converters/question/question-text-layout-to-slide-text-layout';
import { questionToSlideImageReference } from '../converters/question/question-to-slide-image-reference';
import { questionLayoutToSlideLayout } from '../converters/question/question-layout-to-slide-layout';
import { questionShowCorrectToSlideCorrectAnswerMode } from '../converters/question/question-show-correct-to-slide-correct-answer-mode';

type GuessTheNumberStaticContentSchema = Extract<
  StaticContentSchema,
  { type: 'guess-the-number' }
>;

const DEFAULT_RESPONSE_RANGE_SCALE = 10;

export const getGuessTheNumberQuestionStaticContent = (question: Question) => {
  const [slideLayout] = questionLayoutToSlideLayout(question.layout);
  const { textSizeOffset } = questionTextLayoutToSlideTextLayout(
    question.layout,
  );
  const content: GuessTheNumberStaticContentSchema = {
    type: 'guess-the-number',
    meta: question.title_meta,
    layout: slideLayout,
    image: questionToSlideImageReference(question),
    styledTitle: question.question_styled ?? {},
    textSizeOffset,
    interactiveContentStyling: {
      responseRangeScale:
        question.module_custom_data?.scale || DEFAULT_RESPONSE_RANGE_SCALE,
      correctAnswerMode: questionShowCorrectToSlideCorrectAnswerMode(
        question.show_correct,
      ),
    },
  };

  return content;
};
