import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Text } from '../text';
import { Badge, BadgeWrap, type BadgeT, type BadgeTypes } from '../badge';
import { TabGeneric } from './TabGeneric';

export interface TabT extends React.ComponentProps<typeof TabGeneric> {
  children: React.ReactNode;
  fullWidth?: boolean | undefined;
  badge?: BadgeTypes | BadgeT | undefined;
  compact?: boolean;
}

const isBadge = (badge: BadgeT | BadgeTypes | undefined): badge is BadgeT => {
  return (badge as BadgeT)?.type !== undefined;
};

export const Tab = ({
  disabled,
  active = false,
  children,
  badge,
  fullWidth = true,
  compact = false,
  className,
  ...props
}: TabT) => {
  let badgeToRender;

  if (isBadge(badge)) {
    badgeToRender = badge;
  } else if (badge) {
    badgeToRender = <Badge compact type={badge} />;
  }

  return (
    <TabGeneric
      disabled={disabled}
      active={active}
      className={clsx(
        'items-center ',
        fullWidth ? 'flex-1 w-full' : 'flex-none w-auto',
        className,
      )}
      {...props}
      compact={compact}
    >
      <BadgeWrap
        className="flex-auto"
        badge={badgeToRender && (badgeToRender as React.ReactNode)}
        compact
      >
        <Text
          className={clsx(
            'font-semibold leading-125 truncate',
            compact ? 'text-87.5' : 'text-100',
            disabled ? 'text-disabled' : active ? 'text' : 'text-weaker',
          )}
        >
          {children}
        </Text>
      </BadgeWrap>
    </TabGeneric>
  );
};
