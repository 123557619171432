import type { Series } from '@mentimeter/http-clients';
import type { CompatibilitySlideDeck } from '../../../compatibility-types';
import { convertKeysToSnakeCase } from '../../../utils/convert-keys-to-snake-case';
import { liveChatSettingsToSeriesCfaProperties } from './live-chat-settings-to-series-cfa-properties';
import { musicSettingsToSeriesMusicUrl } from './music-settings-to-series-music-url';
import { participationIdentityModeSettingsToSeriesVoteAgainEnabled } from './participation-identity-mode-to-series-vote-again-enabled';
import { participationModeToSeriesPaceMode } from './participation-mode-to-series-pace-mode';
import { participationSettingsToSeriesParticipationIdentityMode } from './participation-settings-to-series-participation-identity-mode';
import { qaSettingsToSeriesQaProperties } from './qa-settings-to-series-qa-properties';
import { segmentationsToSeriesSegmentations } from './segmentations-to-series-segmentations';

export const propertyToSeriesProperty = (
  target: CompatibilitySlideDeck,
  prop: keyof Series | 'slideDeckSegmentations',
) => {
  /**
   * Ignore invalid properties potentially produced by SWR
   * and/or our own Proxy implementation.
   */
  if ((prop as string) === 'then') {
    return undefined;
  }

  // As support for slide deck properties become available, they will appear here.
  switch (prop) {
    case 'id':
    case 'series_id':
      return target.slideDeckPublicKey;

    case 'name':
      return target.name;

    case 'qfa_active':
      return qaSettingsToSeriesQaProperties(target.qaSettings).qfa_active;

    case 'qfa_intercom_enabled':
      return qaSettingsToSeriesQaProperties(target.qaSettings)
        .qfa_intercom_enabled;

    case 'qfa_moderation_enabled':
      return qaSettingsToSeriesQaProperties(target.qaSettings)
        .qfa_moderation_enabled;

    case 'qfa_moderation_access_key':
      return qaSettingsToSeriesQaProperties(target.qaSettings)
        .qfa_moderation_access_key;

    case 'comments_enabled':
      return liveChatSettingsToSeriesCfaProperties(target.liveChatSettings)
        .comments_enabled;

    case 'reactions':
      return target.reactionSettings?.reactionsAllowed;

    case 'profanity_lang':
      return target.profanitySettings?.profanityFilters?.join(',') ?? '';

    case 'language':
      return target.languageSettings?.presentationLanguage;

    case 'vote_key':
      return target.participationSettings?.participationKey;

    case 'participation_identity_mode':
      return participationSettingsToSeriesParticipationIdentityMode(
        target.participationSettings,
      );

    case 'vote_again_enabled':
      return participationIdentityModeSettingsToSeriesVoteAgainEnabled(
        target.participationSettings,
      );

    case 'theme_id':
      return target.themeId;

    case 'theme': {
      return convertKeysToSnakeCase(target.legacyThemeSettings);
    }

    case 'segmentations':
      return segmentationsToSeriesSegmentations(
        target.slides,
        target.segmentations,
      );

    case 'slideDeckSegmentations':
      return target.segmentations;

    case 'music_url':
      return musicSettingsToSeriesMusicUrl(target.musicSettings);

    case 'workspace_id':
      return target.ownershipSettings?.workspaceId;

    case 'owner_id':
      return target.ownershipSettings?.ownerId;

    case 'pace': {
      return {
        mode: participationModeToSeriesPaceMode(target.participationSettings),
      };
    }

    default:
      // @ts-expect-error [MX] TODO
      return target[prop];
  }
};
