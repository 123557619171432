import type { Question } from '@mentimeter/http-clients';
import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type {
  CompatibilityInteractiveContent,
  CompatibilitySlide,
} from '../../compatibility-types';
import {
  RESPONSE_POLICY_NO_RESTRICTION,
  RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES,
} from '../../constants';
import { responseCountDisplayModeToQuestionPercentDefault } from '../converters/interactive-content';
import { NoInteractiveContentError } from '../errors/no-interactive-content-error';
import type { CommonQuestionProperty } from './get-common-question-property';

export const getQuestionChoicesProperty = (
  target: CompatibilitySlide,
  prop: keyof Question,
) => {
  const interactiveContent = target.interactiveContents[0];
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'multiple-choice' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  if (interactiveContent) {
    switch (prop as keyof Omit<Question, CommonQuestionProperty>) {
      case 'max_votes':
        return getMaxVotes(interactiveContent);
      case 'percent_default':
        return getQuestionResponseCountDisplayMode(interactiveContent);
      default:
        return undefined;
    }
  }

  throw new NoInteractiveContentError(target.slideId);
};

const getMaxVotes = (interactiveContent: CompatibilityInteractiveContent) => {
  switch (interactiveContent.responsePolicy) {
    case RESPONSE_POLICY_NO_RESTRICTION:
      return -1;
    case RESPONSE_POLICY_SINGLE_RESPONSE_LIMITED_CHOICES:
      return interactiveContent.maxEntries || -1;
    default:
      return 1;
  }
};

const getQuestionResponseCountDisplayMode = (
  interactiveContent: CompatibilityInteractiveContent,
) => {
  if (
    interactiveContent.styling &&
    'responseCountDisplayMode' in interactiveContent.styling
  ) {
    return responseCountDisplayModeToQuestionPercentDefault(
      interactiveContent.styling.responseCountDisplayMode,
    );
  }
  return false;
};
