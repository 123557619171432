import type { StaticContentEntity as StaticContentSchema } from '@core-api/editor/types/response';
import type { Question } from '@mentimeter/http-clients';
import type { Slide as VotingSlide } from '@mentimeter/voting-schema/api-types-overrides';
import type { CompatibilitySlide } from '../../compatibility-types';

export const getQuestionRankingProperty = (
  target: CompatibilitySlide | VotingSlide,
  prop: keyof Question,
) => {
  const staticContents = target.staticContent as Extract<
    StaticContentSchema,
    { type: 'ranking' }
  >;

  if (prop === 'question_styled') {
    return staticContents.styledTitle ?? {};
  }

  return undefined;
};
