import { useToast } from '@mentimeter/toast';
import type React from 'react';
import { useState } from 'react';
import { Box } from '@mentimeter/ragnar-ui/box';
import { Button } from '@mentimeter/ragnar-ui/button';
import { Dark } from '@mentimeter/ragnar-ui/themes';
import {
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
  PopoverRoot,
} from '@mentimeter/ragnar-ui/popover';
import { Text } from '@mentimeter/ragnar-ui/text';
import { Textarea } from '@mentimeter/ragnar-ui/input/textarea';
import type { PopoverProps } from '../types';
import { PaywallPopoverTrigger } from '../../triggers/PaywallPopoverTrigger';

export const MAX_MESSAGE_LENGTH = 1000;

export interface RequestUpgradePopoverContentProps extends PopoverProps {
  children?: React.ReactNode;
  open: boolean;
  onRequestUpgrade: (message: string) => Promise<void>;
  onOpenChange: (open: boolean) => void;
}

export const RequestUpgradePopoverContent = ({
  onRequestUpgrade,
  onOpenChange,
  title,
  description,
  width,
  open,
  children,
}: RequestUpgradePopoverContentProps) => {
  return (
    <PopoverRoot modal open={open} onOpenChange={onOpenChange}>
      <PaywallPopoverTrigger trigger={() => onOpenChange(true)}>
        {children}
      </PaywallPopoverTrigger>
      <PopoverContent
        onRequestUpgrade={async (message) => {
          onOpenChange(false);
          await onRequestUpgrade(message);
        }}
        title={title}
        description={description}
        width={width}
      />
    </PopoverRoot>
  );
};

export function PopoverContent({
  onRequestUpgrade,
  title,
  description,
  width = '300px',
}: {
  onRequestUpgrade: (message: string) => Promise<void>;
  title: string;
  description: string;
  width: string | undefined;
}) {
  const [message, setMessage] = useState('');
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  return (
    <PopoverContainer width={width}>
      <Dark>
        <Box bg="surface">
          <PopoverHeader>{title}</PopoverHeader>
          <PopoverBody>
            <Text>{description}</Text>
            <Box width="100%" mt={3} alignItems="stretch">
              <Text color="text" mb={2}>
                Message (optional)
              </Text>
              <Textarea
                id="request-upgrade-message"
                data-testid="request-upgrade-message"
                value={message}
                placeholder="Write a message to your admin"
                onChange={handleTextInputChange}
                extend={({ theme }) => ({
                  borderColor: theme.colors.border,
                })}
              />
              {error && (
                <Text mt={2} color="textNegative">
                  {error}
                </Text>
              )}
            </Box>
          </PopoverBody>
          <PopoverFooter>
            <Button
              data-testid="request-upgrade-button"
              variant="positive"
              key="find-admin"
              onClick={handleClickRequestUpgrade}
              size="compact"
            >
              Request upgrade
            </Button>
          </PopoverFooter>
        </Box>
      </Dark>
    </PopoverContainer>
  );

  function handleTextInputChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setError(null);
    setMessage(e.target.value || '');
  }

  async function handleClickRequestUpgrade() {
    if (message.length > MAX_MESSAGE_LENGTH) {
      setError(
        `Your message is too long. Please keep it under ${MAX_MESSAGE_LENGTH} characters.`,
      );
      return;
    }

    await onRequestUpgrade(message);
    showUpgradeRequestedToast();
    setMessage('');
  }

  function showUpgradeRequestedToast() {
    toast.displayToast({
      description: 'Your upgrade request has been sent',
      autoDismiss: true,
    });
  }
}
